var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-select",
    {
      attrs: { filterable: "", clearable: "", disabled: !_vm.notView },
      on: { change: _vm.handleChange },
      model: {
        value: _vm.detailName,
        callback: function ($$v) {
          _vm.detailName = $$v
        },
        expression: "detailName",
      },
    },
    _vm._l(_vm.options, function (item) {
      return _c("el-option", {
        key: item.detailName,
        attrs: { label: item.detailName, value: item.detailName },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }