var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("el-input", {
        ref: "inputRef",
        attrs: {
          placeholder: "选择终端",
          disabled: !_vm.notView,
          readonly: "",
          clearable: "",
        },
        nativeOn: {
          click: function ($event) {
            return _vm.openSelectModal.apply(null, arguments)
          },
        },
        model: {
          value: _vm.pageData.terminalName,
          callback: function ($$v) {
            _vm.$set(_vm.pageData, "terminalName", $$v)
          },
          expression: "pageData.terminalName",
        },
      }),
      _c("SelectConfig", {
        ref: "selectConfig",
        on: { onGetSelect: _vm.onGetSelect },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }