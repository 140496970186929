<template>
  <el-select
    filterable
    v-model="detailName"
    @change="handleChange"
    clearable
    :disabled="!notView"
  >
    <el-option
      v-for="item in options"
      :key="item.detailName"
      :label="item.detailName"
      :value="item.detailName"
    ></el-option>
  </el-select>
</template>

<script>
export default {
  name: 'levelSelect',
  props: {
    notView: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      default: () => [],
    },
    value: String,
  },
  data() {
    return {
      detailName: '',
    };
  },
  created() {},
  watch: {
    value: {
      deep: true,
      handler(val) {
        console.log('🚀 ~ VALUE ~ val:', val);
        this.detailName = val;
      },
    },
  },
  methods: {
    handleChange(value) {
      console.log(value);
      const res = this.options.find((item) => item.detailName === value);
      this.$emit('change', res);
    },
  },
};
</script>
