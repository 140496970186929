import Form, { formCreate } from '@/found/components/form';
import request from '@/found/utils/request';
import terminalList from './components/terminalList.vue';
import levelSelect from './components/levelSelect.vue';

formCreate.component('terminalList', terminalList);
formCreate.component('levelSelect', levelSelect);

export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      rule: [],
      formFunctionCode: 'level_adjustment_management_form',
    };
  },
  async created() {
    await this.getFormRule('level_adjustment_management_form');
  },
  methods: {

    // 获取终端详情
    async getTerminalDetail(terminalCode) {
      const res = await request.get('/cps/fxh/terminalAdjustController/getTerminalInfo', { terminalCode });
      if (res.success) {
        const { result } = res;
        return result;
      }
    },

    // 设置规则
    setRule(item) {
      if (item.field === 'terminalCode') {
        item.props = {
          ...item.props,
          notView: this.formConfig.code !== 'view',
        };
        item.on = {
          ...item.on,
          change: (e) => {
            const obj = e.tableData[0];
            this.getTerminalDetail(obj.terminalCode).then((res) => {
              this.setValue({
                ...res,
                terminalCode: {
                  terminalName: obj.terminalName,
                  terminalCode: obj.terminalCode,
                },
              });
              const adjustDetailName = this.getRule('adjustDetailName');
              this.$nextTick(() => {
                adjustDetailName.props = {
                  ...adjustDetailName.props,
                  options: res.details,
                };
              });
            }).catch((err) => {
              const terminalCodeEl = this.getRule('terminalCode');
              terminalCodeEl.value.tableData = [];
              terminalCodeEl.value.terminalCode = '';
              terminalCodeEl.value.terminalName = '';
            });
          },
        };
      } else if (item.field === 'adjustDetailName') {
        item.props = {
          ...item.props,
          notView: this.formConfig.code !== 'view',
        };
        item.on = {
          ...item.on,
          change: (e) => {
            console.log('🚀 ~ setRule ~ e:', e);
            const adjustPointsEl = this.getRule('adjustPoints');
            adjustPointsEl.props.disabled = false;
            console.log('🚀 ~ setRule ~ adjustPointsEl:', adjustPointsEl);
            this.setValue({
              adjustPoints: e.adjustPoints,
              adjustInterestRuleName: e.interestRuleList.map((val) => val.ruleName).join(','),
              adjustDetailName: e.detailName,
            });
          },
        };
      }
      return item;
    },
    // 渲染完成后执行
    formComplete() {
      const { type, id } = this.formConfig;

      if (type === 'edit' || type === 'view') {
        request.get('/cps/fxh/terminalAdjustController/findById', { id }).then((res) => {
          if (res.success) {
            const { result } = res;
            this.setValue({
              ...result,
              adjustDetailName: result.adjustDetailName,
              terminalCode: { terminalName: result.terminalName },
            });
          }
        });
      }
    },

    // 提交
    submit() {
      const formData = this.getFormData(); // 获取表单的值
      formData.terminalName = formData.terminalCode.terminalName;
      formData.terminalCode = formData.terminalCode.terminalCode;
      console.log('🚀 ~ submit ~ formData:', formData);

      if (formData) {
        // 默认新增
        const url = '/cps/fxh/terminalAdjustController/saveTerminalAdjust';
        // // 有id  就是编辑
        // if (this.formConfig.id) {
        //   formData.id = this.formConfig.id;
        // }
        request.post(url, formData).then((res) => {
          if (res.success) {
            this.$message({
              type: 'success',
              message: '操作成功',
            });
            this.$emit('onClose');
            this.$emit('onGetList');
          }
        });
      }
    },
  },
};
